.Tips-container {
    top: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1;
    position: absolute;
    margin-top: 590vh;
  }

  .Tips-title {
    align-self: flex-end;
    margin-right: 12vw;
    margin-bottom: 100vh;
  }
  
  .Tips {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 4vw;
    padding-right: 4vw;
    height: 35vw;
  }
  
  .TipsIcon {
    height: 8vw;
    display: flex;
    justify-content: space-around;
    align-self: center;
  }
  
  .Kvety {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 36vw;
    margin-top: 220vh;
    margin-left: 55vw;
    padding: 0px;
  }
  
  .Kolace {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33vw;
    margin-top: 90vh;
  }
  
  .Ubytko {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 40vw;
    margin-top: 90vh;
    margin-left: 51vw;
  }