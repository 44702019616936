.ToTipsDown-arrow{
    background: url(/public/assets/icons/down-arrow.svg);
    background-size: contain;
    border-radius: 50%;
    border: none;
    display: flex;
    align-self: center;
    height: 7vw;
    width: 7vw;
    position: relative;
    bottom: 5vh;
    margin-top: 15vh;
    transition: 0.5s;
  }
  
  .ToTipsDown-arrow:hover{
    transform: scale(1.1);
    bottom: 3vh;
  }
  
  .Introduction-container {
    min-height: 162vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0;
    margin: 0;
    z-index: 1;
    position: absolute;
    margin-top: 190vh;
    padding: 0;

  }
  
  .Introduction {
    align-self: flex-start;
    font-size: 1.8vw;
    width: 35vw;
    margin: 50vh 0 0 5vw;
  }
  
  .ChurchInfo {
    align-self: flex-start;
    font-size: 1.8vw;
    width: 35vw;
    margin: 90vh 0 0 0vw;
    margin-left: 60vw;
  }
  
  .WeddingInfo {
    align-self: flex-end;
    font-size: 1.8vw;
    width: 35vw;
    margin: 90vh 0vw 0 0;
    margin-left: 60vw;
  }
  
  .ShowOnMapIcon {
    border: none;
    width: 3vw;
    height: 3vw;
    margin-bottom: -3.5vh;
    transition: 0.3s;
  }
  
  .ShowOnMapIcon:hover {
    transform: scale(1.1);
  }

  .ToTipsDown-arrow{
    background: url(/public/assets/icons/down-arrow.svg);
    background-size: contain;
    border-radius: 50%;
    border: none;
    display: flex;
    align-self: center;
    height: 7vw;
    width: 7vw;
    position: relative;
    bottom: 5vh;
    margin-top: 15vh;
    transition: 0.5s;
  }
  
  .ToTipsDown-arrow:hover{
    transform: scale(1.1);
    bottom: 3vh;
  }