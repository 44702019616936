@font-face {
  font-family: 'hijrnotes';
  src: url(../public/assets/fonts/Hijrnotes/Hijrnotes_PERSONAL_USE_ONLY.ttf);
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'montserrat';
  src: url(../public/assets/fonts/Montserrat/Montserrat-Thin.ttf);
  font-weight: normal;
  font-style: normal;

}

html {
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
  background-color: #eeeced;
  font-family: 'montserrat';
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 1310vh;
  width: 100vw;
}

h1{
  font-weight: normal;
}

h2{
  font-weight: bold;
  font-size: 3vw;
}

.Paragraph1{
  font-size: 1.8vw;
}

.Note {
  font-size: 1.2vw;
  color: rgb(88, 88, 88);
}

a {
  font-weight: bold;
  color: rgb(61, 61, 61);
  text-decoration: none;
}

.background {
  background-image: url(../public/assets/images/Initial_background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 220vh;
  width: 100%;
  bottom: 0;
  padding: 0;
  margin-top: 87vh;
  z-index: 0;
}