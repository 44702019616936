.App-header {
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'hijrnotes';
    font-size: 5vw;
    color: rgb(44, 44, 44);
    z-index: 1;
    position: absolute;
    align-items: center;
    justify-content: flex-start;
    left: 5%;
    right: 5%;
  }
  
  .HeaderOfHeaders {
    bottom: 20vh;
  }
  
  
  .ToIntroDown-arrow{
    background: url(/public/assets/icons/down-arrow.svg);
    background-size: contain;
    border-radius: 50%;
    border: none;
    height: 7vw;
    width: 7vw;
    position: absolute;
    bottom: 0;
    margin-bottom: 4vh;
    transition: 0.5s;
  }
  
  .ToIntroDown-arrow:hover{
    transform: scale(1.1);
    margin-bottom: 1vh;
  }