.FooterContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 1280vh;
    z-index: 1;
    padding-top: 15vw;

}