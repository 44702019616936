.BibleQuoteContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: 100vh;
    left: 32%;
    right: 32%;
    height: 25vw;
}

.BibleQuote {
    background-color: white;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 25vw;
    width: 25vw;
    padding: 5vw;
}

.BibleQuoteParagraph {
    font-size: 1.4vw;
    z-index: 1.5;
    top: 25%;
}

.BibleQuoteParagraph1 {
    font-size: 1vw;
}