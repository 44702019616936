.modalBackground {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    top: 0;
    left: 0;
    backdrop-filter: blur(20px);
    
}

.modalContainer {
    position: fixed;
    width: 50vw;
    height: 23vw;
    z-index: 1040;
    top: 25%;
    bottom: 25%;
    left: 20%;
    right: 20%;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    background-color: white;
    border-radius: 0.3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
}

.closeButton {
    align-self: flex-end;
    border: none;
    background: none;
    margin-right: -3vw;
    font-size: 1.2vw;
    transition: .2s ease-in-out 0s;
}

.closeButton:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.modalItem {
    height: 4vw;
    width: 100%;
    border: none;
    font-family: 'montserrat';
    font-size: 1.8vw;
    text-align: center;
}

.modalDescription {
    width: 89%;
    margin-top: 3vw;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: center;
    font-size: 1vw;
    align-items: center;
    justify-content: center;
}

.checkbox {
    margin: 0;
    padding: 0;
    width: 1vw;
    height: 1vw;
    margin-right: 1vw;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    margin-top: 1vw;
    width: 35%;
    justify-content: space-around;
}

.linkButton {
    padding: 1vw 2vw;
    border: none;
    border-radius: 5px;
    transition: .2s ease-in-out 0s;
}

.linkButton:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.reserveButton {
    padding: 1vw 2vw;
    border: none;
    border-radius: 5px;
    transition: .2s ease-in-out 0s;
}

.reserveButton:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.reserveButton:hover:disabled {
    cursor: no-drop;
    transform: scale(1);
}

.disclaimer {
    margin-top: 2vw;
    font-size: 0.9vw;
}