.Contributors-container {
    background-color: white;
    top: 1174vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    z-index: 1;
  }
  
  .Contributors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 4vw;
    padding-right: 4vw;
    margin-top: 10vh;
    padding-bottom: 10vh;
  }
  
  .ContributorsLogo {
    height: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s ease-in-out 0s;
  }

  .ContributorsLogo:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  .ContributorsParagraph {
    font-size: 1.2vw;
  }
  
  .DivoZel {
    flex: 0.18;
    flex-direction: column;
    align-items: space-between;
    height: 100%;
  }
  
  .WeddingSisters {
    flex: 0.18;
    flex-direction: column;
    align-items: space-between;
    height: 100%;
  }
  
  .Gastro {
    flex: 0.18;
    flex-direction: column;
    align-items: space-between;
    height: 100%;
  }
  
  .DJFabo {
    flex: 0.18;
    flex-direction: column;
    align-items: space-between;
    height: 100%;
  }

  .Babik {
    flex: 0.18;
    flex-direction: column;
    align-items: space-between;
    height: 100%;
  }