.SvadobneDary-WIP {
    height: 90vh;
    top: 1085vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 1;
    backdrop-filter: blur(20px);
    flex: 0.2;
}

.GiftTipItemsContainer{
    max-height: 90vh;
    width: 75%;
    padding-top: 2vh;
    padding-bottom: 5vh;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.GiftTipItemsContainer::-webkit-scrollbar {
    display: none;
}

.GiftTipItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.Item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
    left: 0;
    right: 0;
    margin-top: 0.75vw;
    margin-bottom: 0.75vw;
}

.ItemTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4vw;
}

.ItemText {
    width: 100%;
    height: 4vw;
    padding: 0;
    padding-left: 2vw;
    border: none;
    border-radius: 0.3vw;
    background-color: white;
    font-family: 'montserrat';
    font-size: 1.4vw;
    transition: .2s ease-in-out 0s;
}

.ItemText:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.ItemReserveButton {
    width: 10%;
    height: 3vw;
    border: white;
    background-color: white;
    border-radius: 0.3vw;
    font-family: 'montserrat';
    font-size: 1.4vw;
    transition: .2s ease-in-out 0s;
}
.ItemReserveButton:hover {
    cursor: pointer;
    transform: scale(1.1);
    background-color: rgb(244, 255, 238);
}

.ItemLinkButton {
    position: relative;
    height: 3vw;
    transition: .2s ease-in-out 0s;
}

.ItemLinkIcon {
    position: relative;
    height: 3vw;
}

.ItemLinkButton:hover {
    transform: scale(1.1);
}

.howTo {
    font-size: 1.4vw;
    max-width: 73%;
}